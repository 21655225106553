.fc-theme-standard td,
.fc-theme-standard th,
.fc {
  border: none !important;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 8px;
}

.fc .fc-toolbar-title {
  font-size: 0.85em;
  padding-left: 4px;
}

a.fc-col-header-cell-cushion {
  font-size: 0.85em;
  color: #989898;
  font-weight: normal;
}

.fc .fc-daygrid-day-number {
  font-size: 0.85em !important;
  color: #1b444a;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

th.fc-col-header-cell.fc-day.fc-day-sun,
th.fc-col-header-cell.fc-day.fc-day-mon,
th.fc-col-header-cell.fc-day.fc-day-tue,
th.fc-col-header-cell.fc-day.fc-day-wed,
th.fc-col-header-cell.fc-day.fc-day-thu,
th.fc-col-header-cell.fc-day.fc-day-fri,
th.fc-col-header-cell.fc-day.fc-day-sat {
  padding-bottom: 1em;
}

.fc .fc-button-primary {
  background-color: transparent;
  border: none;
  color: #000000;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1em;
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #000000;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:disabled {
  background-color: #eeeeee;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #e9f5ff;
  border-radius: 50px;
}

.fc .fc-daygrid-event-harness-abs,
.fc .fc-daygrid-event-harness {
  cursor: default;
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.4em;
}

/* below is for the ability for these cals to be tiny... may need a parent class */

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: transparent;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0;
}

.fc .fc-daygrid-day-number {
  font-size: 1em !important;
}

a.fc-col-header-cell-cushion {
  font-size: 1em !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  z-index: 20;
  top: 0;
  color: white;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events a .fc-event-title.fc-sticky {
  color: transparent;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0;
}

.fc .fc-daygrid-day-events {
  margin-top: 0;
}

th.fc-col-header-cell.fc-day.fc-day-sun,
th.fc-col-header-cell.fc-day.fc-day-mon,
th.fc-col-header-cell.fc-day.fc-day-tue,
th.fc-col-header-cell.fc-day.fc-day-wed,
th.fc-col-header-cell.fc-day.fc-day-thu,
th.fc-col-header-cell.fc-day.fc-day-fri,
th.fc-col-header-cell.fc-day.fc-day-sat {
  padding-bottom: 0.5em;
}
